<template>
	<DialogBase
		:dialogWidth="900"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>
			{{ selectedModel ? `Update` : `Create` }} Signature
		</template>

		<v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
			<FormMaker :schema="form_structure" :model="form_data"></FormMaker>
		</v-form>

		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">
				{{ selectedModel ? `Update` : `Create` }}
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { required, minLen } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null },
			user: { required: true, default: null }
		},
		data() {
			return {
				isValidForm: false,
				form_data: {
					payload: {
						is_active: 1,
						name: "default",
						application_type: this.user?.agent?.assigned_product_type,
						communication_type: "sms",
						body: null
					}
				}
			};
		},
		mounted() {
			if (this.selectedModel) {
				this.form_data = Object.assign({}, this.selectedModel);
			} else {
			}
		},
		computed: {
			form_structure() {
				let form = {
					payload: {
						is_active: {
							type: "select",
							name: "is_active",
							label: "Active?",
							...this.$helpers.formFieldsBasicLayout,
							items: [
								{ text: "Yes", value: 1 },
								{ text: "No", value: 0 }
							],
							itemText: "text",
							itemValue: "value",
							rules: []
						},
						application_type: {
							type: "select",
							name: "application_type",
							label: "Application Type",
							...this.$helpers.formFieldsBasicLayout,
							disabled: true,
							items: this.$store.state.application.application_types,
							itemText: "text",
							itemValue: "value",
							col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
							rules: [required()]
						},
						communication_type: {
							type: "select",
							name: "communication_type",
							label: "Communication Type",
							...this.$helpers.formFieldsBasicLayout,
							disabled: true,
							items: this.$store.state.application.communication_types,
							itemText: "text",
							itemValue: "value",
							col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
							rules: [required()]
						},
						body: {
							type:
								this.form_data.payload.communication_type == "email"
									? "text-editor"
									: "textarea",
							name: "body",
							label: `Body`,
							...this.$helpers.formFieldsBasicLayout,
							rows: 6,
							rules: []
						}
					}
				};
				return form;
			}
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			refresh(val = false) {
				this.$emit("refresh");
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let data = {
					...this.form_data
				};

				if (this.selectedModel)
					return await this.post(
						`${this.baseUrl}/agent-portal/update-signature`,
						data,
						true
					).then(data => {
						if (data == "undefined") return;
						this.showMessageFromResponseDta(data);

						if (data.code == 200) {
							this.refresh();
							this.closeDialog();
						}
					});

				return await this.post(
					`${this.baseUrl}/agent-portal/create-signature`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
						this.closeDialog();
					}
				});
			}
		}
	};
</script>
