<template>
	<v-container id="user-profile" fluid tag="section">
		<v-row justify="center">
			<v-col cols="12" md="8">
				<v-card>
					<v-card-title class="transparent-2">
						<div class="display-2 font-weight-light">Profile</div>
						<v-spacer></v-spacer>

						<agentSignatureManagement
							v-if="false && user.agent"
							@refresh="refresh()"
							:user="user"
						/>
					</v-card-title>

					<v-form>
						<v-container class="py-0">
							<!-- <v-row>
								<v-col cols="12" class="text-right">
									<v-btn color="success" class="mr-0"> Update Profile </v-btn>
								</v-col>
							</v-row> -->

							<v-card class="transparent" elevation="0">
								<v-card-text>
									<template v-for="(value, key) in userDetails">
										<v-row v-if="value" no-gutters :key="key" class="my-6">
											<template>
												<v-col cols="6">
													<div class="text-capitalize">
														{{ key }}
													</div>
												</v-col>
												<v-col cols="6">
													<div class="text-capitalize">
														<b v-html="value"> </b>
													</div>
												</v-col>
											</template>
										</v-row>
									</template>
									<v-divider></v-divider>
									<changeUserAccountPassword
										@refresh="refresh()"
										:user="user"
									/>
									<v-divider></v-divider>
									<customerApiCredentialsManagement
										v-if="user.role == 'customer'"
										@refresh="refresh()"
										:user="user"
									/>
								</v-card-text>
							</v-card>
						</v-container>
					</v-form>
				</v-card>
			</v-col>

			<v-col cols="12" md="4">
				<base-material-card
					class="v-card-profile"
					:avatar="
						`https://ui-avatars.com/api/?name=${currentUser.name}&background=218dec&color=fff&size=64`
					"
				>
					<v-card-text class="text-center">
						<h6 class="display-1 mb-1 grey--text text-uppercase">
							{{ currentUser.name }}
						</h6>
						<h4 class="title font-weight-light mb-3 black--text text-uppercase">
							{{ currentUserRole }}
						</h4>
					</v-card-text>
				</base-material-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import customerApiCredentialsManagement from "@/components/general/customerApiCredentialsManagement.vue";
	import changeUserAccountPassword from "@/components/general/changeUserAccountPassword.vue";
	import agentSignatureManagement from "@/components/general/agentSignatureManagement.vue";

	export default {
		head: {
			title: function() {
				return {
					inner: "Profile"
				};
			}
		},
		components: {
			customerApiCredentialsManagement,
			changeUserAccountPassword,
			agentSignatureManagement
		},
		computed: {
			user() {
				return this.currentUser;
			},
			agent() {
				return this.user?.agent?.payload;
			},
			customer() {
				return this.user?.customer?.payload;
			},
			userDetails() {
				let user = this.user;
				let agent = this.agent;
				let customer = this.customer;
				return {
					"user id": user?.user_id,
					name: user?.name,
					phone: user?.phone_number,
					email: user?.email,
					username: user?.username,
					"agent id": user?.agent?.agent_id,
					"assigned product type": agent?.assigned_product_type,
					"customer id": user?.customer?.customer_id,
					"first name": customer?.first_name,
					"last name": customer?.last_name,
					"business name": customer?.business_name,
					"business address": customer?.business_address,
					description: customer?.description,
					"phone verified": customer?.is_phone_verified,
					"email verified": customer?.is_email_verified
				};
			}
		},
		methods: {
			refresh() {
				// document.location.reload();
			}
		}
	};
</script>
